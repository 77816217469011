import React from "react";

const OtherLinks = () => {
  return (
    <div>
      <a
        href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
        class="other-links-left"
      >
        <div class="img">
          <img
            src="https://download.ocms365.com/v2/jlk/awd/memDesktop/img/appdownload_e5e6f20.gif"
            alt="download"
            className="fixed-right-img"
          />
        </div>
        <span>DOWNLOAD APP</span>
      </a>
    </div>
  );
};

export default OtherLinks;
