import React from "react";
import { VerticalSwiper } from "./VerticalSwiper";

const Goal = () => {
  return (
    <div className="Goalwrapper">
      <div className="row">
        <div className="col-6 ranking">
          <div className="title">
            <h3 className="">Ranking D&T</h3>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div class="ranking-box">
                <div class="ranking dragon">
                  <div class="ranking-img">
                    <div class="ranking-img-border">
                      <div class="game-img">
                        <img
                          src="https://download.ocms365.com/v2/common/JDB/PlatformGameList.31593_3?version=0"
                          className="img-goal border rounded-2"
                          alt="images"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="ranking-info">
                    <div class="winner">
                      <div class="player">markjuli***</div>
                      <div class="score">
                        62600.00<span className="d-none">x</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div class="ranking-box">
                <div class="ranking tiger">
                  <div class="ranking-img">
                    <div class="ranking-img-border">
                      <div class="game-img">
                        <img
                          src="https://download.ocms365.com/v2/common/JILI/PlatformGameList.10457_3?version=0"
                          className="img-goal border rounded-2"
                          alt="images"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="ranking-info">
                    <div class="winner">
                      <div class="player">rog***</div>
                      <div class="score">
                        1000.00<span className="d-none">x</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 winList ">
          <div className="title">
            <h3 className="">Win List</h3>
          </div>
        <VerticalSwiper/>
        </div>
      </div>
    </div>
  );
};

export default Goal;
