import React from 'react'

export const FooterInformation = [
    { label: "About us" },
    { label: "Agent Joining" },
    { label: "Fisher game" },
    { label: "User Guide" },
    { label: "FAQ" },
    { label: "Betting Rules" },
    { label: "Payment Methods" },
    { label: "privacy-policy" },
    { label: "Terms and Conditions" },
  ];
  