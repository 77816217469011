import React from "react";
import NavBar from "../header/NavBar";
import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import NabBarbottom from "../header/NabBarbottom";
import "../../styles/layout.css";
import "../../styles/Fixed.css";
import OtherLinks from "../fixedsection/OtherLinks";
import RightLinks from "../fixedsection/RightLinks";
import { GoToTop } from "../fixedsection/GoToTop";
const Layout = () => {
  return (
    <>
      <div>
        <div className=" fixed-top relative">
          <NavBar />
          <NabBarbottom />
        </div>
        <div className="main-content ">
          <Outlet />
        </div>
        <OtherLinks />
        <RightLinks />
        <Footer />
        <GoToTop />
      </div>{" "}
    </>
  );
};

export default Layout;
