import React from "react";
import { FaBullhorn } from "react-icons/fa";
const News = () => {
  return (
    <div>
      <div class="news-wrapper ">
        <ul class="news-scroll marquee ps-0 mb-0 border-bottom border-top">
          <div
            class="js-marquee-wrapper py-1"
          >
            <div class="js-marquee">
              <li>
                <marquee
                  behavior=""
                  direction=""
                  className="d-flex align-items-center"
                >
                  <span className="marquee-text ">
                    <FaBullhorn className="me-2 my-auto" />
                    <span class="title">JILI Bet History Delay Notice:</span>
                  </span>

                  <span class="content">
                    The JILI game platform have bet history delay issue, Due to
                    games can launch normally, we will not switch the game
                    platform to maintenance, If you have any question, please
                    contact us.
                  </span>
                </marquee>
              </li>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default News;
