import React from "react";
import { Link } from "react-router-dom";
const Games = () => {
  return (
    <section className="gameslist">
      <div className="container">
        <div className="games d-flex align-items-center">
          <div className="container">
            <div className="row ">
              <div className="col-auto pe-5 fs-5">
                <a
                  href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
                  className="text-decoration-none  games-link active"
                >
                  HOT GAMES
                </a>
              </div>
              <div className="col-auto pe-5 fs-5 ">
                <a
                  href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
                  className="text-decoration-none text-white games-link"
                >
                  {" "}
                  NEW GAMES
                </a>
              </div>
              <div className="col-auto pe-5 fs-5 ">
                <a
                  href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
                  className="text-decoration-none text-white games-link"
                >
                  FEATURED GAMES
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-3 ">
          <div className="col flex-fill  container-relative game-hover relative">
            <img
              src="https://download.ocms365.com/v2/common/JILI/PlatformGameList.12259_3?version=0"
              alt="iamges"
              srcset=""
              className="games-items rounded-3 pb-3 d-flex align-items-center mx-auto z-1"
            />
            <div className="absolute text-right text-uppercase">Super Ace</div>
            <div class="middle z-2">
              <Link to="https://bj88ph.live/af/Zd946zCP/jilikogambling">
                <button className="btn btn-danger btn-game-over text-uppercase">
                  Play real
                </button>
              </Link>
            </div>
          </div>
          <div className="col flex-fill  container-relative game-hover ">
            {" "}
            <img
              src="https://download.ocms365.com/v2/common/JILI/PlatformGameList.30720_3?version=0"
              alt="images"
              srcset=""
              className="games-items rounded-3 pb-3 d-flex align-items-center mx-auto"
            />
            <div className="absolute text-right text-uppercase">
              Golden Empire
            </div>
            <div class="middle z-2">
              <Link to="https://bj88ph.live/af/Zd946zCP/jilikogambling">
                <button className="btn btn-danger btn-game-over text-uppercase">
                  Play real
                </button>
              </Link>
            </div>
          </div>
          <div className="col flex-fill  container-relative game-hover ">
            {" "}
            <img
              src="https://download.ocms365.com/v2/common/JILI/PlatformGameList.30815_3?version=0"
              alt="iamges"
              srcset=""
              className="games-items rounded-3 pb-3 d-flex align-items-center mx-auto"
            />
            <div className="absolute text-right text-uppercase">
              Forture Gems
            </div>
            <div class="middle z-2">
              <Link to="https://bj88ph.live/af/Zd946zCP/jilikogambling">
                <button className="btn btn-danger btn-game-over text-uppercase">
                  Play real
                </button>
              </Link>
            </div>
          </div>
          <div className="col flex-fill  container-relative game-hover ">
            {" "}
            <img
              src="https://download.ocms365.com/v2/common/JILI/PlatformGameList.30417_3?version=0"
              alt="iamges"
              srcset=""
              className="games-items rounded-3 pb-3 d-flex align-items-center mx-auto"
            />
            <div className="absolute text-right text-uppercase">
              Boxing King
            </div>
            <div class="middle z-2">
              <Link to="https://bj88ph.live/af/Zd946zCP/jilikogambling">
                <button className="btn btn-danger btn-game-over text-uppercase">
                  Play real
                </button>
              </Link>
            </div>
          </div>
          <div className="col flex-fill  container-relative game-hover ">
            {" "}
            <img
              src="https://download.ocms365.com/v2/common/FC/PlatformGameList.11067_3?version=0"
              alt="iamges"
              srcset=""
              className="games-items games-border rounded-3 pb-3 d-flex align-items-center justify-content-center mx-auto"
            />
            <div className="absolute text-right text-uppercase">
              Night market
            </div>
            <div class="middle z-2">
              <Link to="https://bj88ph.live/af/Zd946zCP/jilikogambling">
                <button className="btn btn-danger btn-game-over text-uppercase">
                  Play real
                </button>
              </Link>
            </div>
          </div>
          <div className="col flex-fill game-hover container-relative ">
            {" "}
            <img
              src="https://download.ocms365.com/v2/common/FC/PlatformGameList.12152_3?version=0"
              alt="iamges"
              srcset=""
              className="games-items games-border rounded-3 pb-3 d-flex align-items-center justify-content-center mx-auto"
            />
            <div className="absolute text-right text-uppercase">
              CHINESE NEW YEAR
            </div>
            <div class="middle z-2">
              <Link to="https://bj88ph.live/af/Zd946zCP/jilikogambling">
                <button className="btn btn-danger btn-game-over text-uppercase">
                  Play real
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Games;
