import React from "react";
import { FooterLink } from "../../Data/Footer";
import { FooterInformation } from "../../Data/FooterInfo";
import SwiperBrand from "./SwiperBrand";

const Footer = () => {
  return (
    <>
      <footer class="footer-top text-white text-center text-lg-start">
        {/* <!-- Grid container --> */}
        <div class="container-fluid pb-4">
          {/* <!--Grid row--> */}
          <div class="row">
            {/* <!--Grid column--> */}
            <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
              <p>
                Jiliko provides the best, most trusted and safest gambling
                experience. Jiliko Casino Online Philippines is a famous online
                casino in the Philippines; we currently have tens of thousands
                of players visiting our website every day, our game comes with
                the highest odds, that’s why we believe you can enjoy your
                valuable time with Jiliko.
              </p>
              <p>
                Jiliko Online Casino Games Philippines provides a full game for
                a variety taste, with the best game quality and highest payouts!
                We cooperate with the most trusted software vendor such as CQ9,
                JILI, SE GAMING, Fa Chai, KA-Gaming etc. you can fine Slot
                Games, live Casino, Poker, Baccarat, Roulette, Fish Shooter,
                Sportsbook and Lottery in Jiliko, guaranteed to bring all
                players a perfect gaming experience. We provide players with a
                fully functional and secure gaming platform, mobile version of
                Jiliko provides in multiple languages and on multiple devices
                (PC/ laptop, iOS, Android), so you can play games slot machine
                app casi o with us anywhere and every time.
              </p>
              <p>
                Register now and get your first deposit bonus! Jiliko is
                licensed and regulated, providing you with the highest level of
                security. Our team is constantly improving research and
                development, from new games to the better bonus; we hope to
                bring players a different gambling experience. Join the gambling
                world of Jiliko now, experience the best online casino in the
                Philippines.
              </p>
            </div>

            <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
              <h5 class="text-uppercase title mb-2">Menu</h5>
              <div className="border-footer mb-2"></div>
              <ul className="list-unstyled- mb-0 ps-0">
                {FooterLink.map((item, index) => (
                  <li key={item}>
                    <a
                      href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
                      className="text-decoration-none footer-links"
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
              <h5 class="text-uppercase title mb-2">Information</h5>
              <div className="border-footer-info mb-2"></div>
              <ul className="list-unstyled- mb-0 ps-0">
                {FooterInformation.map((item, index) => (
                  <li key={item}>
                    <a
                      href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
                      className="text-decoration-none footer-links"
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div class="col-lg-2 col-md-6 mb-4 mb-md-0 mb-4 ">
              <h5 class="text-uppercase title text-center mb-0">Links</h5>
              <div className="border-footer-links mb-2"></div>
              <div className="links my-4">
                <div>
                  <ul className="links-ul mb-4">
                    <li>
                      <a
                        href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
                        class="socail-link-a"
                        data_vue_tag="SocialButton"
                      >
                        <img
                          src="https://download.ocms365.com/v2/jlk/Community.1?version=4"
                          alt="imgss-links"
                          className="links-icon"
                        />
                        <span></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
                        class="socail-link-a"
                        data_vue_tag="SocialButton"
                      >
                        <img
                          src="https://download.ocms365.com/v2/jlk/Community.2?version=13"
                          alt="images-icon"
                          className="links-icon"
                        />
                        <span></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
                        class="socail-link-a"
                        data_vue_tag="SocialButton"
                      >
                        <img
                          src="https://download.ocms365.com/v2/jlk/Community.3?version=3"
                          alt="icons-links"
                          className="links-icon"
                        />
                        <span></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
                        class="socail-link-a"
                        data_vue_tag="SocialButton"
                      >
                        <img
                          src="https://download.ocms365.com/v2/jlk/Community.4?version=7"
                          alt="icons-links"
                          className="links-icon"
                        />
                        <span></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="service-box">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
                    class="socail-link-a"
                  >
                    <img
                      src="https://download.ocms365.com/v2/jlk/awd/memDesktop/img/24h-icon_84ae81c.png"
                      alt="imgas-services"
                      className="services"
                    />
                    <span></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="brand">
            <h4>GAME PROVIDERS</h4>
            <SwiperBrand />
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div class="copyright v-html-default">
          Copyright © JILIKO. All rights reserved.
        </div>
      </div>
    </>
  );
};

export default Footer;
