import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../styles/footer.css";
// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import { GameProviders } from "../../Data/GameProviders";

export default function SwiperBrand() {
  return (
    <div>
      <Swiper
        slidesPerView={9}
        spaceBetween={30}
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        {GameProviders.map((item, index) => (
          <div key={item} className="">
            <SwiperSlide className="swiper-slide-icon">
              <img
                src={item.icon}
                alt="icons"
                className="game-provider-icon"
                srcset=""
              />
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
    </div>
  );
}
