import React from "react";

const SocialMed = () => {
  return (
    <div className="container">
      <div className="pt-5">
        <div className="row">
          <div className="col-10">
            <div class="ratio ratio-16x9">
              <iframe
                width="1280"
                height="20"
                src="https://www.youtube.com/embed/WO7Pp0u4qPM"
                title="JAYSON CASTRO &amp; ARWIND SANTOS are playing games in #jiliko"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                className="youtube-content"
              ></iframe>
            </div>
          </div>
          <div className="col-2">
            <iframe
              data-v-7cded034=""
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fjilikocasino&amp;tabs=timeline&amp;width=240&amp;height=500&amp;small_header=true&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId"
              width="240"
              height="auto"
              scrolling="no"
              frameborder="0"
              allowfullscreen="allowfullscreen"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              className="overflow-hidden border-0 fb-content"
              title="facebook content"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMed;
