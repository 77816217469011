import icon1 from "../assets/icon1.png";
import icon2 from "../assets/icon2.png";
import icon3 from "../assets/icon3.png";

export const NavBar = [
  {
    label: "Slot game",
    icon: icon1,
    alt: "menu-links ",
    links: "https://bj88ph.live/af/Zd946zCP/jilikogambling",
  },
  {
    label: "Live game",
    icon: icon2,
    alt: "menu-links",
    links: "https://bj88ph.live/af/Zd946zCP/jilikogambling",
  },
  {
    label: "Fisher game",
    icon: icon3,
    alt: "menu-links",
    links: "https://bj88ph.live/af/Zd946zCP/jilikogambling",
  },
  {
    label: "Bingo game",
    icon: "https://download.ocms365.com/v2/jlk/awd/memDesktop/img/bingogame-btn_aa57a04.png",
    alt: "menu-links",
    links: "https://bj88ph.live/af/Zd946zCP/jilikogambling",
  },
  {
    label: "Sports",
    icon: "https://download.ocms365.com/v2/jlk/awd/memDesktop/img/sportgame-btn_139a4c7.png",
    alt: "menu-links",
    links: "https://bj88ph.live/af/Zd946zCP/jilikogambling",
  },
  {
    label: "Sabong",
    icon: "https://download.ocms365.com/v2/jlk/awd/memDesktop/img/bingogame-btn_aa57a04.png",
    alt: "menu-links",
    links: "https://bj88ph.live/af/Zd946zCP/jilikogambling",
  },
  {
    label: "Other game",
    icon: "https://download.ocms365.com/v2/jlk/awd/memDesktop/img/cockfightinggame-btn_3783616.png",
    alt: "menu-links",
    links: "https://bj88ph.live/af/Zd946zCP/jilikogambling",
  },
  {
    label: "Promotion",
    icon: "https://download.ocms365.com/v2/jlk/awd/memDesktop/img/promotion-btn_63beb55.png",
    alt: "menu-links",
    links: "https://bj88ph.live/af/Zd946zCP/jilikogambling",
  },
  {
    label: "Ranking D&T",
    icon: "https://download.ocms365.com/v2/jlk/awd/memDesktop/img/ranking-btn_cbc14af.png",
    alt: "menu-links",
    links: "https://bj88ph.live/af/Zd946zCP/jilikogambling",
  },
  {
    label: "Cinema",
    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMWSURBVHgB7Z2BbdNAFIafEQOUDdIJKOoCbhYAxAAEFmAE0gnYANwFQCxAOkHJBDQbtBNg3ovPyGqj6i6q4/+9/p/05DRNz2m+vPPd5e4iQgghhBBCSHiqu3e0bVvr4Y3Ga42ZkDFYp7ioqupy+Iv/QlTEkR5+aNRCDkmjca5iNvbDVojKmOlhJcyIqbjWmJuUXshvPZwImZKVCplXKmOhP3wTMjWtWJaoEKuqaiEIrEzIjd44EoLAjQlphaDQPhMCxfPcB2oLoMp5XEnG5Za55zlaLb74DadF23P6m/vwnHOUlMkMAYNCwKAQMCgEDAoBg0LAoBAwKAQMCgGDQsDg4CIWHFxEg0LAoBAwKAQMCgGDQsCgEDAoBAwKAYNCwKAQMLwI2Wi8StOGzjQuJCheBhdNxnp4R5ok/lliLaFoXQh5aEKdPv2ldGIi4H+0V10t9XAsQaqxEBf17cqjqlrozQ/SXW/cEqqVpVIaDcuWc3FK1GbvUpxWYyGFWBsgrWpdiLNqLHTHMIlppMsWF9WY+2ZvLunfNDG/BLfv8nQmOSSnth78owDzZDKkp3CF1KFps5e0eWfwvqsFmPBCBiIsM2YaXwWY0NeQgYwX0o13XQn4YGTYDEkyLCtq6bJiJg4IJ2QgYiadiFocEUpIkmHV06cU7rYMCSHEa/W0C9dCBhdt64G7q5524UKIvfB3+4YRqqddeGn21n022DH1tt9K14y15myY7aW8CPmicZxE2KwTGyD8LgH3iOSSNiy4pA0NCgGDQsCgEDAoBAwKAYNCwKAQMCgEDAoBo2S0N3eIpWTKzr7DNrnnGLv8knNklVmys3VWNhXubL1XhnJna3IwKAQMCgGDQsCgEDAoBAwKAYNCwKAQMLJ76qm3+aiMUeYjlF/0N5nnyC6T04Cw4DQgNEzIrRAUbk3IWggKaxPidqOWYNi1vOm/T90mL58JmZIr/WzltL+o2+4G10Km4o/GO7uxFZJ2zplrNLL/x56kHHut7fvsT5OD+x0Wrb5qPbzXeKlxImQMNho/LVTEpRBCCCGEkJH5B+ny8jQCjk3yAAAAAElFTkSuQmCC",
    alt: "menu-links",
    links: "https://bj88ph.live/af/Zd946zCP/jilikogambling",
  },
];
