import React from "react";

const Promotion = () => {
  return (
    <div className="container mb-3">
      <h3>Promotion</h3>
      <div className="row">
        <div className="col-lg-6 ">
          <img
            src="https://download.ocms365.com/v2/jlk/PictureLanguage.4.3.1?version=15"
            alt=""
            className="img-goal"
          />
        </div>
        <div className="col-lg-6">
          <img
            src="https://download.ocms365.com/v2/jlk/PictureLanguage.5.3.1?version=11"
            alt=""
            srcset=""
            className="img-goal"
          />
        </div>
      </div>
    </div>
  );
};

export default Promotion;
