import React from "react";
import { NavBar, NavLink } from "../../Data/NavBar";

const NabBarbottom = () => {
  return (
    <div className="bg-dark">
      <div className="header-bottom  ">
        <div className="menu-container" id="menu-container">
          <table className="my-3">
            <th></th>
            <tbody>
              <tr className="mx-3">
                {NavBar.map((item, index) => (
                  <td key={item} className="me-4  td-nav">
                    <a
                      href={item.links}
                      className="text-decoration-none text-center text-white fw-medium fs-6 ms-2"
                    >
                      <img
                        src={item.icon}
                        alt={item.alt}
                        srcset=""
                        height={27}
                        width={27}
                        className="me-2"
                      />{" "}
                      {item.label}
                    </a>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NabBarbottom;
