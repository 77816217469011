import React from "react";

export const FooterLink = [
  { label: "Slot game" },
  { label: "Live game" },
  { label: "Fisher game" },
  { label: "Bingo game" },
  { label: "Sports" },
  { label: "Sabong" },
  { label: "Other game" },
  { label: "Promotion" },
  { label: "Ranking D&T" },
];
