import React from "react";

const RightLinks = () => {
  return (
    <div className="other-links-right">
      <div className="links-content-block">
        <div className="links-info">
          <div>
            <a
              href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
              class="fixed-friendlink-nav"
            >
              <img
                src="https://download.ocms365.com/v2/jlk/Community.1?version=4"
                alt="imgs"
                className="links-icon"
              />
              <span></span>
            </a>
          </div>
          <div>
            <a
              href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
              class="fixed-friendlink-nav"
            >
              <img
                src="https://download.ocms365.com/v2/jlk/Community.2?version=13"
                alt="links"
                className="links-icon"
              />
              <span></span>{" "}
            </a>
          </div>
          <div>
            <a
              href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
              class="fixed-friendlink-nav"
            >
              <img
                src="https://download.ocms365.com/v2/jlk/Community.4?version=7"
                alt="links"
                className="links-icon"
              />
              <span></span>{" "}
            </a>
          </div>{" "}
          <div>
            <a
              href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
              class="fixed-friendlink-nav"
            >
              <img
                src="https://download.ocms365.com/v2/jlk/Community.3?version=3"
                alt="links"
                className="links-icon"
              />
              <span></span>{" "}
            </a>
          </div>{" "}
          <div>
            <a
              href="https://bj88ph.live/af/Zd946zCP/jilikogambling"
              class="CustomService fixed-friendlink-nav"
            >
              <img
                src="https://download.ocms365.com/v2/jlk/awd/memDesktop/img/24h-icon_84ae81c.png"
                alt="links"
                className="links-icon"
              />
              <span></span>{" "}
            </a>
          </div>
        </div>

        <div className="box">
          <div className="imgs">
            <img
              src="https://download.ocms365.com/v2/jlk/awd/memDesktop/img/24h-icon_84ae81c.png"
              alt="imgs"
              srcset=""
              className="links-iconss"
            />
          </div>
          <span>CONTACT US</span>
        </div>
      </div>
    </div>
  );
};

export default RightLinks;
