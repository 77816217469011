import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const SwiperHero = () => {
  return (
    <div>
      <Swiper
        rewind={true}
        pagination={true}
        navigation={true}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            src="/assets/img/PictureLanguage.154.webp"
            alt="Baccaratlive Winner"
            srcset="Baccaratlive Winner"
            className="w-100"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/PictureLanguage.202.webp"
            alt="VIP Bonnus"
            srcset="VIP Bonnus"
            className="w-100"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/PictureLanguage.190.webp"
            alt="VIP Bonnus"
            srcset="VIP Bonnus"
            className="w-100"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/PictureLanguage.133.webp"
            alt="VIP Bonnus"
            srcset="VIP Bonnus"
            className="w-100"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/PictureLanguage.137.webp"
            alt="VIP Bonnus"
            srcset="VIP Bonnus"
            className="w-100"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/PictureLanguage.156.webp"
            alt="VIP Bonnus"
            srcset="VIP Bonnus"
            className="w-100"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/PictureLanguage.114.webp"
            alt="BestOnlineCasino"
            srcset="VIP Bonnus"
            className="w-100"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/PictureLanguage.170.webp"
            alt="VIP Bonnus"
            srcset="VIP Bonnus"
            className="w-100"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/PictureLanguage.190.webp"
            alt="VIP Bonnus"
            srcset="VIP Bonnus"
            className="w-100"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/PictureLanguage.137.webp"
            alt="VIP Bonnus"
            srcset="VIP Bonnus"
            className="w-100"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/PictureLanguage.221.webp"
            alt="VIP Bonnus"
            srcset="VIP Bonnus"
            className="w-100"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SwiperHero;
