import { useState } from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  // const [isCommunityVisible, setCommunityVisible] = useState(false);

  // const handleArrowClick = () => {
  //   setCommunityVisible(!isCommunityVisible);
  //   console.log(isCommunityVisible);
  // };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="container-fluid navbar-section ">
      <div className="">
        <div className="row px-3 px-lg-5">
          <div className="col-2"></div>
          <div class="col-11 d-flex justify-content-center gap-3">
            <div className="row d-flex">
              <div class="col-lg-4 col-sm-6 col-md-6 d-flex align-items-center justify-content-center order-lg-1 order-sm-2">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="btn-danger d-flex h-75 px-3  btn-header-top rounded-start rounded-0 border-0 py-2">
                    <Link to={"https://bj88ph.live/af/Zd946zCP/jilikogambling"}>
                      <div class="text-center px-3 my-auto btn-header pointer-cursor">
                        Login In
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="bg-danger rounded-end rounded-0 border-start ">
                  <div
                    class="btn-arrow-down btn-danger d-flex h-75 px-3  btn-header-top rounded-end rounded-0 border-0"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div class="text-center btn-hover-login pointer-cursor">
                      <i class="bi bi-chevron-down"></i>
                    </div>
                  </div>
                </div>
                <div
                  className={`community-wrap dropdown border-fill ${
                    isHovered ? "" : "d-none"
                  }`}
                >
                  <p className="text-white d-flex py-2 m-0 Or">
                    <span className="">
                      <hr width="106px" className="" />
                    </span>
                    Or
                    <span className="">
                      <hr width="106px" className="" />
                    </span>
                  </p>
                  <div className="d-flex justify-content-center my-auto mb-3">
                    <Link to={"https://bj88ph.live/af/Zd946zCP/jilikogambling"}>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAe1BMVEX///9MZKivutg6VZ////9DXaSsuNdVbKx5jL6irtE+WKHs7/bL0uaNnMdugrjm6vPQ1ujb4O7V2+vGzuO0vtqksdOfrNCWpMyPn8mGl8WCk8JofbZjeLNbca/r7vWwu9lxhbpTaqtOZ6lHYKb3+Pvu8Pe4wtyeq9B0h7v+JCeEAAAAA3RSTlPy/PfX5qpuAAABHklEQVRo3u3ay27CMBCF4aQzLhAouRJypRR6e/8n7Kpt4lRRZI1HBZ1/m8W3iOSzsIPQeC8MSKHAkPcMECDfAQHyv5BolDyyqvI0Pm0HvdWySNRkPO0oipxj/qsnSaRg9o2YhP0jKftHEvaPbNg/0rMCslNAOlZACg3kNItUIkjLVj7OrponFc3+p4sIcrSJ1738aB1spCN55NEy3kkB+dRAXu4GWQEBAmRSaSEkhLTrQbm9t+tRxhVJeXnOSLLciMkV2S1HMg0k10AOGkijgbQaSK+BXBSQbaSAJKSAPGsglQZydkfixUjnjtSb38rM/g3l4OtVaBk/LIRuduOBAAECBAgQIECAAAECBMgMQhLXTVeaze053EMwrjdzhV/2RIjmVMupVgAAAABJRU5ErkJggg=="
                        alt="facebook"
                        height={30}
                        width={30}
                        className="me-4"
                        srcset=""
                      />
                    </Link>
                    <Link to={"https://bj88ph.live/af/Zd946zCP/jilikogambling"}>
                      <img
                        src="https://download.ocms365.com/v2/jlk/awd/memDesktop/img/google_1b7efe8.png"
                        alt="facebook"
                        height={30}
                        width={30}
                        srcset=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 order-sm-1 order-lg-2 d-flex justify-content-sm-center">
                <div>
                  <a href="https://bj88ph.live/af/Zd946zCP/jilikogambling">
                    <img
                      src="https://download.ocms365.com/v2/jlk/Picture.2?version=2"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-6 order-lg-3 order-sm-2 d-flex justify-content-center align-items-center">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="btn-danger d-flex h-75 px-3 btn-dark rounded-3 border py-2">
                    <Link to={"https://bj88ph.live/af/Zd946zCP/jilikogambling"}>
                      <div class="text-center px-3 my-auto btn-header pointer-cursor">
                        Register
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1 d-flex justify-content-end">
            <div className="d-flex gap-3">
              <div class="d-flex align-items-center">
                <Link to={"https://bj88ph.live/af/Zd946zCP/jilikogambling"}>
                  <span class="btn-rights left d-flex justify-content-center align-items-center me-3">
                    <i class="bi bi-bell text-white fs-5"></i>
                  </span>
                </Link>
                <div class=" text-white ">
                  <Link to={"https://bj88ph.live/af/Zd946zCP/jilikogambling"}>
                    <span class="badge border rounded-pill p-3 btn-rights d-flex align-items-center">
                      English <i class="bi bi-chevron-down ms-2"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
