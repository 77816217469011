import React from "react";
import "../styles/header.css";
import "../styles/Home.css";
import "../styles/footer.css"
import "../styles/SwiperHome.css"

import SwiperHero from "../components/Home/SwiperHero";
import News from "../components/Home/News";
import Games from "../components/Home/Games";
import SocialMed from "../components/Home/SocialMed";
import Promotion from "../components/Home/Promotion";
import Goal from "../components/Home/Goal";
const Home = () => {
  return (
    <div>
      <div className="hero">
        {" "}
        <SwiperHero />
        <News />
        <Games />
        <div className="other-links-section ">
          <SocialMed />
          <Promotion />
          <Goal />
        </div>
      </div>
    </div>
  );
};

export default Home;
