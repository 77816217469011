import React from "react";

export const SwipperWinlistRight = [
  {
    gamelist:
      "https://download.ocms365.com/v2/common/FC/PlatformGameList.31747_3?version=0",
    user: "Che**",
    game: "Golden Genie",
    wintxt: "won",
    bonus: "1345.40",
    gamelist1:
      "https://download.ocms365.com/v2/common/RELAX/PlatformGameList.31844_3?version=0",
    user1: "Che**",
    game1: "Shamrock Money Pot",
    wintxt1: "won",
    bonus1: "1345.40",
  },
  {
    gamelist:
      "https://download.ocms365.com/v2/common/FC/PlatformGameList.32250_3?version=0",
    user: "Noy**",
    game: "Golden Boy",
    wintxt: "won",
    bonus: "2345.40",
    gamelist1:
      "https://download.ocms365.com/v2/common/PGV2/PlatformGameList.32615_3?version=0",
    user1: "Che**",
    game1: "PG",
    wintxt1: "won",
    bonus1: "1345.40",
  },
  {
    gamelist:
      "https://download.ocms365.com/v2/common/R88/PlatformGameList.31072_3?version=0",
    user: "Net**",
    game: "Money Roling",
    wintxt: "won",
    bonus: "545.40",
    gamelist1:
      "https://download.ocms365.com/v2/common/JILI/PlatformGameList.32842_3?version=0",
    user1: "Che**",
    game1: "Golden Genie",
    wintxt1: "won",
    bonus1: "1345.40",
  },
  {
    gamelist:
      "https://download.ocms365.com/v2/common/FC/PlatformGameList.31588_3?version=0",
    user: "Hooe**",
    game: "Robin Hood",
    wintxt: "won",
    bonus: "1345.40",
    gamelist1:
      "https://download.ocms365.com/v2/common/JILI/PlatformGameList.32842_3?version=0",
    user1: "Che**",
    game1: "Fortune Game",
    wintxt1: "won",
    bonus1: "1345.40",
  },
  {
    gamelist:
      "https://download.ocms365.com/v2/common/FC/PlatformGameList.31747_3?version=0",
    user: "Che**",
    game: "Chinese New Year",
    wintxt: "won",
    bonus: "545.40",
    gamelist1:
      "https://download.ocms365.com/v2/common/FC/PlatformGameList.31747_3?version=0",
    user1: "Che**",
    game1: "Golden Genie",
    wintxt1: "won",
    bonus1: "1345.40",
  },
  {
    gamelist:
      "https://download.ocms365.com/v2/common/FC/PlatformGameList.31836_3?version=0",
    user: "Che**",
    game: "Sugar Bang bang",
    wintxt: "won",
    bonus: "1345.40",
    gamelist1:
      "https://download.ocms365.com/v2/common/PGV2/PlatformGameList.7536_3?version=0",
    user1: "Ee**",
    game1: "Cocktail Nignts",
    wintxt1: "won",
    bonus1: "1345.40",
  },
];
