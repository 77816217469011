import React from "react";

export const GameProviders = [
  { icon: "https://download.ocms365.com/v2/common/Platform.31.3?version=1" },
  { icon: "https://download.ocms365.com/v2/common/Platform.39.3?version=1" },
  { icon: "https://download.ocms365.com/v2/common/Platform.40.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.49.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.51.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.52.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.54.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.57.3?version=1" },
  { icon: "https://download.ocms365.com/v2/common/Platform.64.3?version=1" },
  { icon: "https://download.ocms365.com/v2/jlk/Platform.66.3?version=1" },
  { icon: "https://download.ocms365.com/v2/jlk/Platform.70.3?version=1" },
  { icon: "https://download.ocms365.com/v2/common/Platform.71.3?version=0" },
  { icon: "https://download.ocms365.com/v2/jlk/Platform.81.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.93.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.95.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.97.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.30.3?version=1" },
  {
    icon: "https://download.ocms365.com/v2/common/Platform.22.3?version=1533620918",
  },
  { icon: "https://download.ocms365.com/v2/jlk/Platform.171.3?version=0" },
  { icon: "https://download.ocms365.com/v2/jlk/Platform.162.3?version=0" },
  { icon: "https://download.ocms365.com/v2/jlk/Platform.134.3?version=0" },
  { icon: "https://download.ocms365.com/v2/jlk/Platform.122.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.118.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.115.3?version=0" },
  { icon: "https://download.ocms365.com/v2/common/Platform.113.3?version=0" },
  { icon: "https://download.ocms365.com/v2/jlk/Platform.105.3?version=0" },
  {
    icon: "https://download.ocms365.com/v2/common/Platform.1.3?version=1533621034",
  },
];
