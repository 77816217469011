import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { SwipperWinlistRight } from "../../Data/SwipperWinlistRight";

export const VerticalSwiper = () => {
  return (
    <>
      <Swiper
        slidesPerView={3}
        direction={"vertical"}
        loop={true}
        autoplay={{
          delay: 1600,
          disableOnInteraction: false,
        }}
        reverseDirection={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper-home border rounded-3 "
      >
        {SwipperWinlistRight.map((item, index) => (
          <SwiperSlide key={item} className="wins">
            <div className="container">
              <div className="row ">
                <div className="col-6 px-3">
                  <div className="row py-2">
                    <div className="col">
                      <div class="img-winner">
                        <img
                          src={item.gamelist}
                          alt="winner"
                          className="inner-img w-100"
                        />
                      </div>
                    </div>
                    <div className="col d-flex justify-content-start my-auto">
                      <div className="winner-right w-75 ">
                        <span className="user  d-flex justify-content-start fs-6 ">
                          {item.user}
                        </span>
                        <span className="game  d-flex justify-content-start text-danger">
                          {item.game}
                        </span>
                        <span className="wintxt text-muted d-flex justify-content-start">
                          {item.wintxt}
                        </span>
                        <span class="bonus  d-flex justify-content-start">
                          <b>₱</b>
                          {item.bonus}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row py-2">
                    <div className="col">
                      <div class="img-winner">
                        <img
                          src={item.gamelist1}
                          alt="winner"
                          className="inner-img w-100"
                        />
                      </div>
                    </div>
                    <div className="col d-flex justify-content-start m-auto ">
                      <div className="winner-right w-75 ">
                        <span className="user  d-flex justify-content-start fs-6 ">
                          {item.user1}
                        </span>
                        <span className="game  d-flex justify-content-start text-danger">
                          {item.game}
                        </span>
                        <span className="wintxt text-muted d-flex justify-content-start">
                          {item.wintxt1}
                        </span>
                        <span class="bonus  d-flex justify-content-start">
                          <b>₱</b>
                          {item.bonus1}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
